import BaseAPI from "./baseapi";
import { getCSRF } from "./getCSRF";
import { API_URL } from "../config";

type CreateResponseProps = {
	project_id: string;
}

async function createResponse({ project_id }: CreateResponseProps) {

	const url = API_URL+"/response";
	const method = 'POST';

	const body = {
		"project_id": project_id
	};

	await getCSRF();
	const response = await BaseAPI({ method, url, body, timeout: 20000 });
	return response;
}

export { createResponse };