import SearchBar from "../components/SearchBar";
import Messages from "../components/Messages";
import { useParams } from "react-router-dom";
import { useMessages } from "../hooks/MessagesContext";
import { useProject } from "../hooks/ProjectContext";
import { useEffect } from "react";

export default function Home() {
	const { id } = useParams();
	const { finished , messages , chat_loaded , setId , addMessage } = useMessages();
	const { setProjId } = useProject();

	useEffect(() => {
		if (id) {
			setId(id);
			setProjId(id);
		}
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [id]);

	return(
	<div  className="flex flex-col">
		{ chat_loaded ? 
		<div>
			<Messages messages={messages} />
			<SearchBar addMessage={addMessage} finished={finished} />
		</div>:
		<div>
			Loading...(We are still developing, the first message will take a few seconds to load.)
		</div>}
	</div>
	)
}