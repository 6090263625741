import Container from "./Container";
import { useProject } from "../hooks/ProjectContext";
import { ExternalLink } from "lucide-react";
import { Button } from "./ui/button";

export default function Navbar() {
	const { organization_name , url , action_button , action_button_url } = useProject();

	return (
	<nav className="fixed z-50 top-0 flex flex-col w-full justify-start">
		<div className="flex w-full justify-start items-center bg-primary py-4">
			<Container type="primary-x">
				<div className="flex w-full justify-between items-center">
					<a 
					className="flex gap-2 items-center text-white text-2xl font-medium" 
					href={url || "https://www.kashti.io"}
					target="_blank" 
					rel="noreferrer">
						{organization_name}
						{ url &&
						<ExternalLink size={20} className="" /> }
					</a>
					<a
					className="text-white text-sm font-normal"
					href={action_button_url || "https://www.kashti.io"}
					target="_blank"
					rel="noreferrer"
					>
						<Button className="rounded-full h-12 bg-white text-primary">
							{action_button}
						</Button>	
					</a>
				</div>
			</Container>
		</div>
		{/* <div className="flex w-full justify-start py-2 bg-secondary-strong"></div>
		<div className="flex w-full justify-start py-2 bg-secondary-light"></div> */}
	</nav>
  );
}