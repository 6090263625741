import BaseAPI from "./baseapi";
import { API_URL } from "../config";

async function getProjectInfo({ projid }: { projid: string }) {

	const url = API_URL+"/project/info?project_id="+projid;
	const method = 'GET';

	const response = await BaseAPI({ method, url });
	return response;

}

export { getProjectInfo }