import { Input } from "./ui/input";

import { Button } from "./ui/button";

import { CornerRightUp } from "lucide-react";

import Container from "./Container";

import { MessageProps } from "../types/Message";

import { useProject } from "../hooks/ProjectContext";

import React from "react";

export default function SearchBar({ addMessage , finished }: { addMessage: (message: MessageProps) => void , finished: boolean }) {
	const [input, setInput] = React.useState("");
	const { action_button , action_button_url } = useProject();

	const handleSubmit = (event: React.FormEvent) => {
		event.preventDefault();
		if (input.trim() === "") {
			return;
		}
		addMessage({ message: input, role: "user" });
		setInput("");
	}

	return (
	<div className="fixed bottom-0 flex justify-center mx-auto w-full">
		<Container>
			{ !finished ?
			<form className="flex gap-2 items-center w-full" onSubmit={handleSubmit}>
				<Input 
				type="text" 
				placeholder="Type in a response..."
				className="h-12"
				value={input}
				onChange={(event) => setInput(event.target.value)}
				/>
				<Button
				className="rounded-full h-12"
				type="submit"
				>
					<CornerRightUp size={20} />
				</Button>
			</form>
			:
			<a
			className=""
			href="https://cal.com/chinmayshrivastava/30min"
			>
				{/* <Container> */}
					<div className="flex justify-center items-center w-full">
						<a
						href={action_button_url || "https://www.kashti.io"}
						target="_blank"
						rel="noreferrer"
						className="w-full"
						>
						<Button 
						className="w-full rounded-full h-12"
						type="button"
						>
							{action_button}
						</Button>
						</a>
					</div>
				{/* </Container> */}
			</a>
			}
			<a 
			className="text-primary text-sm text-right w-full mt-2 px-2"
			href="https://www.kashti.io"
			target="_blank"
			rel="noreferrer"
			>Powered by Kashti.io</a>
		</Container>
	</div>
  );
}