import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import './App.css';
import Home from "./home";
import Navbar from "./components/Navbar";
import Providers from "./Providers";

function App() {
  return (
    <Providers>
    <div className="App h-screen bg-[var(--screen-bg)]"
    // add image to the bg
    // style={{
    //   backgroundImage: "url('/noise.png')"
    // }}
    >
      <Navbar />
      <div className="h-24"></div>
      <Router>
        <Routes>
          <Route path="/:id" element={<Home />} />
          <Route path="*" element={
            <div className="size-full flex-col justify-center items-center">
              <h1>404 Page Not Found</h1>
              <a href="https://www.kashti.io/" className="underline text-blue-800">kashti.io</a>
            </div>
          } />
        </Routes>
      </Router>
      <div className="h-28"></div>
    </div>
    </Providers>
  );
}

export default App;
