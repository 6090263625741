import { MessagesProvider } from "./hooks/MessagesContext";
import { ProjectProvider } from "./hooks/ProjectContext";

export default function Providers({ children }: { children: React.ReactNode }) {
	return (
		<MessagesProvider>
			<ProjectProvider>
				{children}
			</ProjectProvider>
		</MessagesProvider>
	);
}