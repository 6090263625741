import { createContext } from "react";
import React from "react";
import { getProjectInfo } from "../helpers/getProjectInfo";

type ProjectContextType = {
    projid: string;
    organization_name: string;
    url: string | null;
    action_button: string | null;
    action_button_url: string | null;
    setProjId: (id: string) => void;
}

const ProjectContext = createContext<ProjectContextType>({
    projid: "",
    organization_name: "",
    url: "",
    action_button: "",
    action_button_url: "",
    setProjId: () => {},
});

function ProjectProvider({ children }: { children: React.ReactNode }) {
    const [projid, _setProjId] = React.useState("");
    const [organization_name, _setOrganizationName] = React.useState("");
    const [url, _setUrl] = React.useState("");
    const [action_button, _setActionButton] = React.useState("");
    const [action_button_url, _setActionButtonUrl] = React.useState("");

    const setProjId = (id: string) => {
        _setProjId("proj_id"+id);
        if (localStorage.getItem("proj_id"+id)) {
            const data = JSON.parse(localStorage.getItem("proj_id"+id) || "");
            _setOrganizationName(data.organization_name);
            _setUrl(data.url);
            _setActionButton(data.action_button);
            _setActionButtonUrl(data.action_button_url);
        } else {
            getProjectInfo({ projid: id }).then((data) => {
                _setOrganizationName(data.organization_name);
                _setUrl(data.url);
                _setActionButton(data.action_button);
                _setActionButtonUrl(data.action_button_url);
                localStorage.setItem("proj_id"+id, JSON.stringify(data));
            });
        }
    };

    return (
        <ProjectContext.Provider value={{ projid, organization_name, url, action_button, action_button_url, setProjId }}>
            {children}
        </ProjectContext.Provider>
    );
}

function useProject() {
    const context = React.useContext(ProjectContext);
    if (context === undefined) {
        throw new Error("useProject must be used within a ProjectProvider");
    }
    return context;
}

export { ProjectProvider, useProject };