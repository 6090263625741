import { createContext } from "react";
import { MessageProps } from "../types/Message";
import { createResponse } from "../helpers/createResponse";
import { sendMessage } from "../helpers/sendMessage";
import React from "react";

type MessageContextType = {
	id: string;
	finished: boolean;
	chatid: string;
	chat_loaded: boolean;
	messages: MessageProps[];
	setId: (id: string) => void;
	setChatId: (id: string) => void;
	addMessage: (message: MessageProps) => void;
};

const MessagesContext = createContext<MessageContextType>({
	id: "",
	finished: false,
	chatid: "",
	chat_loaded: false,
	messages: [],
	setId: () => {},
	setChatId: () => {},
	addMessage: () => {},
});

function MessagesProvider({ children }: { children: React.ReactNode }) {
	const [id, _setId] = React.useState("");
	const [finished, _setFinished] = React.useState(false);
	const [chatid, _setChatId] = React.useState("");
	const [chat_loaded, _setChatLoaded] = React.useState(false);
	const [messages, setMessages] = React.useState<MessageProps[]>([]);

	React.useEffect(() => {
		if (id && chatid) {
			localStorage.setItem(id, JSON.stringify({ chatid, messages, finished }));
		}
	}, [messages, id, chatid, finished]);

	const addMessage = async (message: MessageProps) => {
		setMessages((prevMessages) => [...prevMessages, message]);
		const res = await sendMessage({ response_id: chatid, message: message.message, is_user_message: message.role === "user" });
		if (res.is_done) {
			_setFinished(true);
			localStorage.setItem(id, JSON.stringify({ chatid, messages, finished: true }));
			return;
		}
		const _nextMessage: MessageProps = {
			message: res.message,
			role: "bot",
		}
		setMessages((prevMessages) => [...prevMessages, _nextMessage]);
	};

	const setId = async (id: string) => {
		_setId(id);
		// if the id exists in local storage, then find the chat id and messages
		if (localStorage.getItem(id)) {
			const data = JSON.parse(localStorage.getItem(id) || '');
			_setChatId(data.chatid);
			setMessages(data.messages);
			_setFinished(data.finished)
		} else {
			// if the id does not exist in local storage, then create a new chat id
			const res = await createResponse({ project_id: id });
			const chatid = res.response_id;
			_setChatId(chatid);
			const _firstMessage: MessageProps = {
				message: res.message,
				role: "bot",
			}
			setMessages([_firstMessage]);
			localStorage.setItem(id, JSON.stringify({ chatid, messages: [_firstMessage], finished: false }));
		}
		_setChatLoaded(true);
	}

	const setChatId = (id: string) => {
		_setChatId(id);
	}

	return (
		<MessagesContext.Provider value={{ 
			id, 
			finished, 
			chatid, 
			chat_loaded, 
			messages, 
			setId, 
			setChatId, 
			addMessage 
		}}>
			{children}
		</MessagesContext.Provider>
	);
}

function useMessages() {
	const context = React.useContext(MessagesContext);
	if (!context) {
		throw new Error("useMessages must be used within a MessagesProvider");
	}
	return context;
}

export { MessagesProvider, useMessages };