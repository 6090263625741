const containerTypes: Record<string, string> = {
	"primary": "max-w-3xl w-full p-4",
	"primary-x": "max-w-3xl w-full px-4",
	"hero": "py-12",
	"feature": "py-12",
}

export default function Container({
	  type = "primary",
	  children,
}: Readonly<{
  type?: string;
  children: React.ReactNode;
}>) {
  return (
	<div className={"flex h-full w-full justify-center"}>
		<div className={`flex flex-col mx-auto ${containerTypes[type]}`}>
	  		{children}
	  	</div>
	</div>
  );
}