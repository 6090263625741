import Container from "./Container";
import { MessageProps } from "../types/Message";
import { motion } from "framer-motion";
import { useEffect, useRef } from "react";

const Message = ({ message , role }: MessageProps) => {
	return (
		<motion.div
		className={"flex w-full gap-2 my-4" + (role === 'bot' ? "" : " flex-row-reverse")}
		initial={{ opacity: 0, y: 20 }}
		animate={{ opacity: 1, y: 0 }}
		transition={{ duration: 0.3 }}
		>
			<div className={"flex justify-center items-center size-8 rounded-md p-4"
			+ (role === 'bot' ? " bg-primary text-white" : " bg-secondary text-black")}> 
				{role === 'bot' ? "K" : "U"}
			</div>
			<div className={"flex items-center justify-start max-w-[75%] p-4 rounded-md text-left"
				+ (role === 'bot' ? " bg-secondary text-black" : " bg-primary text-white")}>
				{message}
			</div>
		</motion.div>
	);
}

export default function Messages({ messages }: {messages: MessageProps[]}) {
    const messagesEndRef = useRef<null | HTMLDivElement>(null);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(scrollToBottom, [messages]);

    return (
        <Container type="primary-x">
            <div className="flex flex-col">
                {messages.map((message, index) => (
                    <Message key={index} message={message.message} role={message.role} />
                ))}
                <div ref={messagesEndRef} />
            </div>
        </Container>
    );
}