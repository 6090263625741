import BaseAPI from "./baseapi";
import { getCSRF } from "./getCSRF";
import { API_URL } from "../config";

type sendMessageProps = {
	response_id: string;
	message: string;
	is_user_message: boolean;
}

async function sendMessage({ response_id, message, is_user_message = true }: sendMessageProps) {

	const url = API_URL+"/message";
	const method = 'POST';

	const body = {
		"response_id": response_id,
		"message": message,
		"is_user_message": is_user_message
	};

	await getCSRF();
	const response = await BaseAPI({ method, url, body, timeout: 10000 });
	return response;
}

export { sendMessage };